var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"log-entry-tables","fluid":"","tag":"section"}},[_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('LogEntryFilter',{on:{"onFilterChange":_vm.onFilterChange}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('log-entry-list')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("Translate")(_vm._f("getText")(item.action,_vm.log_entry_action_items))))]}},{key:"item.actor.role",fn:function(ref){
var item = ref.item;
return [(item.actor)?[_vm._v(_vm._s(_vm._f("Translate")(_vm._f("getText")(item.actor.role,_vm.role_items))))]:_vm._e()]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.timestamp,"YYYY-MM-DD HH:mm:ss")))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }