<template>
  <v-container id="common-time-filter" fluid tag="section">
    <v-row>
      <v-col>
        <form class="rounded pt-6 pb-8" @submit.prevent>
          <div class="">
            <date-picker
              v-model="search_range"
              :value="search_range"
              mode="dateTime"
              :masks="masks"
              is-range
              is24hr
            >
              <template v-slot="{ inputValue, inputEvents, isDragging, updateValue }">
                <div style="display: flex;">
                  <div>
                    <div class="flex flex-col sm:flex-row justify-start items-center">
                      <div class="relative flex-grow" style="display: flex;">
                        <v-icon>mdi-calendar</v-icon>
                        <input
                          class="flex-grow pr-2 py-1 border rounded w-full"
                          :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          style="border: groove;"
                        />
                      </div>
                      <div>
                        <v-icon>mdi-arrow-right</v-icon>
                      </div>
                      <div class="relative flex-grow" style="display: flex;">
                        <v-icon>mdi-calendar</v-icon>
                        <input
                          class="flex-grow pr-2 py-1 border rounded w-full"
                          :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          style="border: groove;"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="margin-left: 1.5rem;">
                    <v-btn-toggle v-model="toggle_exclusive">
                      <v-btn
                        v-for="(item, key) in date_filters"
                        :key="key"
                        :color="getColor(key)"
                        small
                        @click="updateValue(getDays(key))"
                      >
                        {{ $t(item.text) }}
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </div>
              </template>
            </date-picker>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { DatePicker } from 'v-calendar';
export default {
  props: {
    default_selected: null,
    label: {
      type: String,
      default: function() {
        return this.$t("time");
      }
    }
  },
  data: vm => ({
    toggle_exclusive: null,
    change_exclusive: 0,
    show_arrows: false,
    text_field_props: {
      prependIcon: "mdi-calendar",
      outlined: true,
      dense: true
    },
    time_props: {
      useSeconds: true,
      ampmInTitle: true
    },
    filterItem: {
      search_start: "",
      search_end: ""
    },
    date_filters: [
      { text: "today" },
      { text: "yesterday" },
      { text: "this-month" },
      { text: "last-month" },
      { text: "last-3-month" }
    ],
    masks: {
      inputDateTime24hr: 'YYYY-MM-DD HH:mm:ss.SSS'
    },
  }),
  components: {
    DatePicker
  },
  computed: {
    search_range: {
      set(val){
        this.filterItem.search_start = val.start;
        this.filterItem.search_end = val.end;
        let time_range = this.getRange()
        this.$emit("setTimeRange", time_range);
        this.change_exclusive -= 1;
      },
      get () {
        let range = {
          start: (new Date()).setHours(0,0,0,0),
          end: (new Date()).setHours(23,59,59,999)
        }
        return range
      }
    }
  },
  watch: {
    toggle_exclusive: function() {
      if (this.toggle_exclusive != null) {
        this.change_exclusive = 2;
      } else if(this.toggle_exclusive === undefined) {
        this.filterItem.search_start = null;
        this.filterItem.search_end = null;
      }
    },
    ["filterItem.search_start"](val) {
      let time_range = this.getRange()
      this.$emit("setTimeRange", time_range);
      this.change_exclusive -= 1;
    },
    ["filterItem.search_end"](val) {
      let time_range = this.getRange()
      this.$emit("setTimeRange", time_range);
      this.change_exclusive -= 1;
    },
    change_exclusive(val){
      console.log(this.change_exclusive)
      if(val < 0){
        this.toggle_exclusive = null;
      }
    }
  },
  methods: {
    onChange() {
      this.$emit("onFilterChange", this.filterItem);
    },
    getRange() {
      let time_range = null;
      if (this.filterItem.search_start && this.filterItem.search_end) {
        time_range =
          this.$moment(this.filterItem.search_start).toISOString() +
          "," +
          this.$moment(this.filterItem.search_end).toISOString();
      }
      return time_range;
    },
    getColor(key) {
      if (key == this.toggle_exclusive) {
        return "primary";
      }
      return null;
    },
    getDays(key) {
      switch (key) {
        case 0:
          return this.setToday();
          break;
        case 1:
          return this.setYesterday();
          break;
        case 2:
          return this.setThisMonth();
          break;
        case 3:
          return this.setLastMonth();
          break;
        case 4:
          return this.setLastThreeMonth();
          break;
        default:
          break;
      }
    },
    setToday(day_diff = 0) {
      var start = new Date();
      start.setHours(0, 0, 0, 0);
      start.setDate(start.getDate() - day_diff);
      var end = new Date();
      end.setDate(end.getDate() - day_diff);
      end.setHours(23, 59, 59, 999);
      this.filterItem.search_start = start;
      this.filterItem.search_end = end;
      return {
        start: start,
        end: end
      }
    },
    setYesterday() {
      return this.setToday(1);
    },
    setMonth(begin, end) {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, m + begin, 1);
      var lastDay = new Date(y, m + end, 0);
      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(23, 59, 59, 999);
      this.filterItem.search_start = firstDay;
      this.filterItem.search_end = lastDay;
      return {
        start: firstDay,
        end: lastDay
      }
    },

    setThisMonth() {
      return this.setMonth(0, 1);
    },
    setLastMonth() {
      return this.setMonth(-1, 0);
    },
    setLastThreeMonth() {
      return this.setMonth(-3, 0);
    }
  },
  created() {
    if (this.default_selected != null) {
      this.toggle_exclusive = parseInt(this.default_selected);
    }
  }
};
</script>
<style scoped>
.pb-8 {
  padding-bottom: 2rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.rounded {
  border-radius: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.text-gray-600 {
  --text-opacity: 1;
  color: #718096;
  color: rgba(113,128,150,var(--text-opacity));
}
.text-gray-900 {
  --text-opacity: 1;
  color: #1a202c;
  color: rgba(26,32,44,var(--text-opacity));
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.sm\:flex-row {
  flex-direction: row;
}
.relative {
  position: relative;
}
.flex-grow {
  flex-grow: 1;
}
.w-full {
  width: 100%;
}
.pr-2 {
  padding-right: 0.5rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.border {
  border-width: 1px;
}
</style>