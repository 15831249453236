<template>
    <v-container id="log-entry-tables" fluid tag="section">
      <CommonAlertDialog
        :show="showAlert"
        :item="alertItem"
        v-on:onCloseAlert="onCloseAlert"
      >
      </CommonAlertDialog>
      <LogEntryFilter v-on:onFilterChange="onFilterChange"></LogEntryFilter>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="$t('log-entry-list')"
        class="px-5 py-3"
      >
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="footerProps"
          multi-sort
        >
          <template v-slot:top="{pagination, options, updateOptions}">
            <v-data-footer 
              :pagination="pagination" 
              :options="options"
              @update:options="updateOptions"
              :show-first-last-page="true"
              :show-current-page="true"
              first-icon="mdi-chevron-double-left"
              last-icon="mdi-chevron-double-right"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              :items-per-page-options="[20, 50, 100]"
            />
          </template>
          <template v-slot:item.action="{ item }">{{
            item.action | getText(log_entry_action_items) | Translate
          }}</template>
          <template v-slot:item.actor.role="{ item }">
            <template v-if="item.actor">{{
              item.actor.role | getText(role_items) | Translate
            }}</template>
          </template>
          <template v-slot:item.timestamp="{ item }">{{
            item.timestamp | moment("YYYY-MM-DD HH:mm:ss")
          }}</template>
        </v-data-table>
      </base-material-card>
    </v-container>
  </template>
  <script>
  import LogEntryFilter from "../filters/LogEntryFilter";
  import BaseTable from "./BaseTable";
  import { role_items, log_entry_action_items } from "@/definition.js";
  export default {
    data: vm => ({
      url: "/account/log_entries/",
      headers: [
        {
          sortable: false,
          text: vm.$i18n.t("action"),
          value: "action",
          width: "10%"
        },
        {
          sortable: false,
          text: vm.$i18n.t("object"),
          value: "object_repr",
          width: "10%"
        },
        {
          sortable: false,
          text: vm.$i18n.t("changes"),
          value: "changes",
          width: "30%"
        },
        {
          sortable: false,
          text: vm.$i18n.t("operator"),
          value: "actor.username",
          width: "10%"
        },
        {
          sortable: false,
          text: vm.$i18n.t("role"),
          value: "actor.role",
          width: "10%"
        },
  
        {
          sortable: false,
          text: "IP",
          value: "remote_addr",
          width: "5%"
        },
        {
          sortable: false,
          text: vm.$i18n.t("time"),
          value: "timestamp",
          width: "12%"
        }
      ],
      log_entry_action_items: log_entry_action_items,
      role_items: role_items,
      defaultTimeRange:true,
      defaultTimeField: 'timestamp',
    }),
    mixins: [BaseTable],
    components: {
      LogEntryFilter
    }
  };
  </script>
  