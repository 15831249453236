<template>
  <v-autocomplete
    :value="data"
    :label="label"
    :loading="loading"
    :items="allItems"
    :item-text="item_text"
    :item-value="item_value"
    :search-input.sync="search"
    :placeholder="placeholder"
    dense
    :outlined="outlined"
    flat
    hide-details
    clearable
    @input="handleInput"
    @compositionstart="flag=false"
    @compositionend="flag=true"
    @focus="focus"
    :return-object="returnObject"
    :ref="selfRef"
    @keyup.right="keyPress"
  >
    <template #label v-if="required">
      <span class="red--text"><strong>* </strong></span>{{ label }}
    </template>
    <template v-slot:item="{ item }">
      {{ itemText(item) }}
    </template>
    <template v-slot:prepend-item>
      <template v-if="prepend">
        <v-list-item
          :title="prependTitle"
          :value="prependTitle"
          @click="onPrependClickEvent"
        >
        <v-list-item-content>
          <v-list-item-title>{{ $t(prependTitle) }}</v-list-item-title>
        </v-list-item-content>
        </v-list-item>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  model: {
    prop: "data",
    event: "selected"
  },
  props: {
    data: null,
    label: null,
    placeholder: null,
    url: null,
    item_text: null,
    item_value: null,
    query_field: null,
    selfRef: 'auto',
    nextRef: null,
    extra_filter: {
      type: Object,
      default: function() {
        return {};
      }
    },
    filter_fields: {
      type: Object,
      default: function() {
        return {};
      }
    },
    outlined: {
      type: Boolean,
      default: true
    },
    cache: {
      type: Array,
      default: function() {
        return []
      },
    },
    extra_condition: {
      type: Object,
      default: function() {
        return {};
      }
    },
    no_data_action: {
      type: Boolean,
      default: false,
    },
    extra_item_text: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    prepend: {
      type: Object,
      default: function() {
        return null
      }
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data: vm => ({
    input: null,
    loading: false,
    items: [],
    search: null,
    flag: true
  }),
  computed: {
    ...mapState(["token"]),
    allItems(){
      return this.items.concat(this.cache)
    },
    prependTitle(){
      return this.prepend ? this.prepend.title : ''
    },
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (this.loading || this.flag===false) return;

      // console.log("watch search() " + val);
      // console.log("watch search() " + this.data);
      val && val !== this.data && this.query(val);
    },
    flag(val,oldVal){
      if (val === true) {
        this.query(this.search)
      }
    },
    items(val){
      if (val.length === 0) {
        if (this.no_data_action === true) {
          this.$emit("noDataAction", this.search)
        }
      }
    },
    data(val){
      let target = this.items.find(item=>item.id==val)
      this.$emit("setItem",target)
    },
  },
  methods: {
    query(v) {
      this.loading = true;
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + this.url;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token
        },
        params: {
          page_size: 100,
          query:
            "{" +
            this.item_text +
            (this.item_value ? "," + this.item_value : "") +
            (this.extra_item_text ? "," + this.extra_item_text : "") +
            "}",
        },
      };
      config.params[this.query_field] = v;
      for (const [key, value] of Object.entries(this.extra_filter)) {
        if (!this.$common_util.isEmpty(value)) {
          if (this.filter_fields.hasOwnProperty(key)) {
            // console.log("filter plus: " + key);
            if (this.filter_fields[key] ==='customer__sp') {
              var query1 = 'customer__id__isnull='+ true
              var query2 = 'customer__id='+this.extra_filter[key]
              query1 = escape(query1)
              query2 = escape(query2)
              var query = "(" + (query1) + ") | (" + (query2) + ")"
              query = escape(query)
              config.params['filters'] = query;
            } else {
              config.params[this.filter_fields[key]] = this.extra_filter[key];
            }
          }
        }
      }
      for (const [key, value] of Object.entries(this.extra_condition)) {
        config.params[key] = value;
      }
      this.axios
        .get(url, config)
        .then(function(response) {
          // console.log(response.data);
          currentObj.items = response.data.results;
        })
        .catch(function(error) {
          console.log(error.response);
        })
        .finally(function() {
          currentObj.loading = false;
        });
    },
    handleInput(val) {
      this.$emit("selected", val);
    },
    focus(){
      this.$emit('focus',this.index)
    },
    itemText(item) {
      var rtn = item[this.item_text];
      return rtn;
    },
    onPrependClickEvent(){
      if (this.prepend) {
        this.$emit('prependAction',this.search)
      }
    },
    keyPress(){
      if (this.nextRef){
        this.$emit('keyPress',this.nextRef)
      }
    }
  }
};
</script>
