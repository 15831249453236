<template>
  <v-container id="current-bet-filter" fluid tag="section">
    <v-row dense>
      <CommonTimeFilter
        v-on:setTimeRange="setTimeRange"
        default_selected="0"
      ></CommonTimeFilter>
    </v-row>
    <v-row dense>
      <v-col
        ><v-select
          v-model="filterItem.action"
          :label="$t('action')"
          :items="log_entry_action_items"
          :item-text="item => $t(item.text)"
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col
        ><v-text-field
          v-model="filterItem.object_repr__icontains"
          :label="$t('object')"
          outlined
          dense
          clearable
          @change="onChange"
          :clear-icon-cb="onChange"
        ></v-text-field>
      </v-col>
      <v-col>
        <CommonAutocomplete
          v-model="filterItem.actor_id"
          :label="$t('operator')"
          :placeholder="$t('input-operator-name')"
          url="/backend/users/?role__in=1,2,3"
          item_text="username"
          item_value="id"
          query_field="username__startswith"
        ></CommonAutocomplete>
      </v-col>
      <v-col
        ><v-select
          v-model="filterItem.actor__role"
          :label="$t('role')"
          :items="role_items"
          :item-text="item => $t(item.text)"
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col
        ><v-text-field
          v-model="filterItem.ip"
          label="IP"
          outlined
          dense
          clearable
          @change="onChange"
          :clear-icon-cb="onChange"
        ></v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn color="primary" dark class="mb-2" @click="onChange">
          <v-icon left>mdi-magnify</v-icon>
          {{ $t("search") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonTimeFilter from "./CommonTimeFilter";
import CommonAutocomplete from "./CommonAutocomplete";
import { role_items, log_entry_action_items } from "@/definition.js";
export default {
  data: vm => ({
    filterItem: {},
    timeRange: null,
    log_entry_action_items: log_entry_action_items,
    role_items: role_items
  }),
  components: {
    CommonTimeFilter,
    CommonAutocomplete,
  },
  methods: {
    onChange() {
      this.filterItem.timestamp__range = this.timeRange;
      this.$emit("onFilterChange", this.filterItem);
    },
    setTimeRange(val) {
      this.timeRange = val;
    }
  }
};
</script>
